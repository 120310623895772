@import "_brand";

.teethCharting {

    margin: 10px 0px;
    padding: 0px 25px;
    height: auto;
    overflow-y: auto;

    .headerLine {
        font: $brand_font_large_bold;
        color: $brand_darkblue;
        justify-content: space-between;
        align-content: center;

        .label {
            padding: 15px 0px;
            text-align: center;
            align-items: center;
        }

        border-bottom: 3px solid $brand_lightgrey;
    }

    .deleted {
        background-color: $brand_red;
        color: $brand_white;
    }
}

.toothBox {
    width: 36px; // Smaller base size for mobile
    height: 48px;
    margin: 11px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-items: center;
    font: $brand_font16_bold;
}

.tooth {
    width: 36px;
    height: 42px;

    background: $brand_white 0% 0% no-repeat padding-box;
    border: 3px solid $brand_blue;
    border-radius: 24px;
    opacity: 1;
    position: relative;
    margin: 3px 0px;
    vertical-align: text-bottom;
    line-height: 67px;
    cursor: pointer;

    &.baby {
        width: 32px;
        height: 38px;
        line-height: 48px;
    }

    &.selected {
        background: $brand_orange 0% 0% no-repeat padding-box;
        border: 3px solid $brand_orange;
        color: $brand_white;
        // margin: 10px 0px;

        &.presentConditions {
            background: $brand_blue 0% 0% no-repeat padding-box;
            border: 3px solid $brand_blue;
            color: $brand_white;
            // margin: 10px 0px
        }

        &.missing {
            background: $brand_statusgrey 0% 0% no-repeat padding-box;
            border: 3px solid $brand_statusgrey;
            color: $brand_white;
        }
    }



    &.invert {
        vertical-align: top;
        line-height: 48px;

        &.baby {
            line-height: 28px;
            margin: 10px 0px
        }
    }
}

@media (max-width: 816px) {
    .toothBox {
        width: 27px;
        height: 36px;
        margin: 2px;
    }

    .tooth {
        width: 27px;
        height: 34px;
        line-height: 34px;

        &.baby {
            width: 22px;
            height: 26px;
            line-height: 26px;
        }

        &.invert {
            vertical-align: top;
            line-height: 36px;

            &.baby {
                line-height: 26px;
                margin: 10px 0px
            }
        }
    }
}

@media (min-width: 816px) {
    .toothBox {
        width: 31.5px;
        height: 42px;
        margin: 2px;
    }

    .tooth {
        width: 31.5px;
        height: 40px;
        line-height: 40px;
    }
}

@media (min-width: 1000px) {
    .toothBox {
        width: 36px;
        height: 48px;
        margin: 3px;
    }

    .tooth {
        width: 36px;
        height: 44px;
        line-height: 44px;
    }
}

@media (min-width: 1225px) {
    .toothBox {
        width: 48px;
        height: 64px;
        margin: 5px;
    }

    .tooth {
        width: 48px;
        height: 56px;
        line-height: 56px;

        &.baby {
            width: 32px;
            height: 38px;
            line-height: 34px;
        }

        &.invert {
            vertical-align: top;
            line-height: 56px;

            &.baby {
                line-height: 34px;
                margin: 10px 0px
            }
        }
    }
}

.toothSelector {
    align-content: center;
    width: 24px;
    height: 24px;
    display: none;
    /* UI Properties */
    // background: $brand_white 0% 0% no-repeat padding-box;
    // background: transparent;
    // border: 2px solid $brand_orange;
    border-radius: 24px;
    // opacity: 1;
    top: -7px;
    z-index: 10;
    vertical-align: text-bottom;
    line-height: 20px;
    cursor: pointer;

    &.baby {
        top: -6px;

        &.invert {
            top: 24px;
            margin: -24px 0px;
        }
    }

    // &.selected {
    //     display: none;
    // }

    // &.invert {
    //     top: 37px;
    // }

    .deleted {
        background-color: $brand_red;
        color: $brand_white;
    }
}

.gridDropDown {
    height: 25px;
    width: auto;
}

.gridDirections {
    font-weight: 900;
    margin: 5px;
    justify-content: center;
}

.gridTextField {
    height: 25px;
    width: 100%;
}

.borderTopLeft {
    box-shadow: -.5px 0px 0px 0px $brand_grey, 0px -.5px 0px 0px $brand_blue;
}

.borderTopRight {
    box-shadow: .5px 0px 0px 0px $brand_blue, 0px -.5px 0px 0px $brand_blue;
    justify-content: right;
}

.borderBottomRight {
    box-shadow: 0px .5px 0px 0px $brand_blue, .5px 0px 0px 0px $brand_blue;
    justify-content: right;
    align-items: flex-end;
}

.borderBottomLeft {
    box-shadow: 0px .5px 0px 0px $brand_blue, -.5px 0px 0px 0px $brand_blue;
    align-items: flex-end;
}

.borderLeft {
    box-shadow: -.5px 0px 0px 0px $brand_grey;
}

.borderRight {
    box-shadow: .5px 0px 0px 0px $brand_blue;
    justify-content: right;
}

.toothDetails {
    .dataGrid {
        &.standard {
            height: calc(100vh - 335px);
        }

        justify-items: center;
        text-align: center;
        align-items: center;
        font: $brand_font16;
        letter-spacing: 0px;
        color: $brand_blue;
        opacity: 1;
        height: 30vh;
    }
}

.toothNotes {

    .TextBox,
    .DateBox {
        width: 100%;
        border: 2px solid $brand_bordergrey;

        &.small {
            width: 100px;
        }

        &:hover {
            background-color: $brand_hoverblue;
        }

        &:focus-within {
            background-color: $brand_hoverblue;
        }
    }

    label {
        text-align: left;
        font: $brand_font13;
        letter-spacing: 0px;
        color: $brand_blue;
        opacity: 1;
        height: 13px;
    }

}

.footer {
    justify-content: right;
}

.red {
    background: $brand_red;
}

.uneruptedModal {
    height: 250px;
    width: 500px;
    padding: 10px;
    justify-content: space-between;

    label {
        padding-left: 15px;
    }
}

.supernumeraryModal {

    height: auto;
    width: 600px;
    min-width: 550px;
    padding: 10px;
    justify-content: space-between;

    label {
        padding-left: 15px;
    }
}

.fillWidth {
    width: 100%;
    //margin-top: 25px;
}

.superGrouping {
    justify-content: space-between;
    //border: 0px 1px 1px 1px solid $brand_grey;
    padding: 15px;
}

.betweenInput {
    width: 3em;
    justify-content: center;
    text-align: center;
    text-justify: center;
}

.superBetween {
    align-items: center;
    justify-content: flex-start;
}