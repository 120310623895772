$brand_font: Proxima Nova;
$brand_font11: normal normal medium 11px/14px $brand_font;
$brand_font12: normal normal bold 12px/14px $brand_font;
$brand_font12bold: normal normal bolder 12px/14px $brand_font;
$brand_font13: normal normal medium 13px/16px $brand_font;
$brand_font13_bold: normal normal bolder 13px/16px $brand_font;
$brand_font16: normal normal normal 16px/19px $brand_font;
$brand_font16_button_Roboto: normal normal bolder 16px/12px Roboto;
$brand_font16_light: normal normal 300 16px/19px $brand_font;
$brand_font16_italic: italic normal bold 16px/19px $brand_font;
$brand_font16_italic_bold: normal normal 900 16px/19px $brand_font;
$brand_font16_bold: normal normal 900 16px/19px $brand_font;
$brand_font16_light: normal normal medium 16px/19px $brand_font;
$brand_font_xl_italic: italic normal 800 30px/36px $brand_font;
$brand_font_xl_bold: normal normal bolder 30px/36px $brand_font;
$brand_font_large_bold: normal normal 800 20px/24px $brand_font;
$brand_header: normal normal 800 30px/36px $brand_font;
$brand_subheader: normal normal 800 20px/24px $brand_font;
$brand_black: #000000;
$brand_blue: #6389ade5;
$brand_bluehover: #0a58ca;
$brand_bluehover_disable: #5291eec0;
$brand_darkblue: #1b4070;
$brand_paleblue: #f7fafc;
$brand_white: #ffffff;
$brand_bordergrey: #cbcbcb;
$brand_hoverblue: #e8f0fe;
$brand_grey: #c2c2c2;
$brand_lightgrey: #ced6df;
$brand_lightergrey: #e6e6e6;
$brand_darkgrey: #7394b5;
$brand_darkergrey: #7e9dbb;
$brand_orange: #ff3e00;
$brand_loadingorange: #ff7c00;
$brand_loadingpurple: #480141;
$brand_red: #fb0f00;
$brand_green: #53880b;
$brand_ligntgreen: #92D400; 
$brand_sessiongreen: #f1f5ea;
$brand_sessionorange: #fef9e5;
$brand_sessionred: #f4ad9c;
$brand_sessionpink: #feeee9;
$brand_sessionblue: #e8f7fc;
$brand_statusslategrey: #3a3a3a;
$brand_statusgrey: #8996a0;
$brand_statusnavyblue: #006699;
$brand_statusorange: #ff9900;
$brand_statusred: #fb0f00;
$brand_statusgreen: #92d400;
$brand_statusblakc: #000000;
$brand_icongrey: #757575;

$brand_small: 300px;
$brand_medium: 600px;
$brand_large: 900px;
$brand_xlarge: 1200px;
