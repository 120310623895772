@import "_brand";
@import '../../node_modules/include-media';


.container {
  margin: 0px 20px;
}

.modalPageHeight {
 overflow-x: hidden;
 min-Height: calc(100vh - 500px);

  &.autoScroll {
    max-height: calc(100vh - 750px);
    overflow-y: auto;
  }
}

.page {
  //max-width: 1180px;
}

.hidden {
  display: none !important;
}

.noHeader {
  padding: 0px;

  &.MuiDialogTitle-root {
    padding: 0px;
  }
}

.noScroll {
  overflow: hidden;

  &.MuiDialogContent-root {
    overflow: hidden;
  }
}

.bottomMargin15 {
  margin-bottom: 15px;
}

.bottomMargin25 {
  margin-bottom: 25px;
}

.modal-smallscreen {
  width: 800px;
  height: 375px;
  padding: 25px;
  margin: 0 auto;
}

.modal-mediumscreen {
  width: 970px;
  height: 1120px;
  padding: 25px;
  margin: 0 auto;
}

.icon {
  cursor: pointer;

  &.small {
    height: 0.6em;
    width: 0.6em;
  }
}

.vivButton {
  background: $brand_white 0% 0% no-repeat padding-box;
  color: $brand_darkblue;
  border: 3px solid $brand_darkblue;
  border-radius: 20px;
  font: normal normal bold 12px/12px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  width: auto;
  margin: 10px;
  padding: 8px;

  &:hover {
    color: $brand_white;
    background: $brand_darkblue 0% 0% no-repeat padding-box;
  }

  &.normal {
    background: $brand_white 0% 0% no-repeat padding-box;
    color: $brand_darkblue;
    border: 3px solid $brand_darkblue;
    border-radius: 20px;
    font: normal normal bold 12px/12px Roboto;
    letter-spacing: 0px;
    opacity: 1;
    width: auto;
    margin: 10px;
    padding: 8px;

    &:hover {
      color: $brand_white;
      background: $brand_darkblue 0% 0% no-repeat padding-box;
    }

    &:disabled {
      background: $brand_grey;
    }
  }

  &.normalToggle {
    background: $brand_white 0% 0% no-repeat padding-box;
    color: $brand_darkblue;
    border-radius: 26px !important;
    border: 3px solid $brand_darkblue !important;
    font: normal normal bold 12px/12px Roboto;
    letter-spacing: 0px;
    opacity: 1;
    width: auto;
    margin: 10px;
    padding: 8px;

    &:hover {
      color: $brand_white;
      background: $brand_darkblue 0% 0% no-repeat padding-box;
    }

    &:disabled {
      background: $brand_grey;
    }

    &.Mui-selected {
      color: $brand_white;
      background: $brand_darkblue 0% 0% no-repeat padding-box;
    }
  }

  &.large {
    min-width: 180px;
    height: 41px;
    border: 3px solid $brand_darkblue;
    border-radius: 20px;
    color: $brand_darkblue;
    margin: 10px;
    font: $brand_font16_button_Roboto;

    &:hover {
      color: $brand_white;
      background: $brand_darkblue 0% 0% no-repeat padding-box;
    }

    &:disabled {
      background: $brand_grey;
    }
  }

  &.xlarge {
    width: auto;
    padding: 0px 25px;
    height: 41px;
    border: 3px solid $brand_darkblue;
    border-radius: 20px;
    color: $brand_darkblue;
    margin: 10px;
    font: $brand_font16_button_Roboto;

    &:hover {
      color: $brand_white;
      background: $brand_darkblue 0% 0% no-repeat padding-box;
    }
  }

  &.orange {
    background: $brand_white 0% 0% no-repeat padding-box;
    border: 3px solid $brand_grey;
    border-radius: 20px;
    color: $brand_orange;
    margin: 10px;

    &:hover {
      color: $brand_white;
      background: $brand_orange 0% 0% no-repeat padding-box;
      border: 3px solid $brand_orange;
    }
  }

  &.invert {
    background: $brand_darkblue 0% 0% no-repeat padding-box;
    color: $brand_white;
    border-radius: 20px;
    margin: 10px;

    &:hover {
      color: $brand_darkblue;
      background: $brand_white 0% 0% no-repeat padding-box;
    }
  }

  &.green {
    border: 3px solid $brand_green;
    background: $brand_green 0% 0% no-repeat padding-box;
    color: $brand_white;
    border-radius: 20px;
    margin: 10px;

    &:hover {
      color: $brand_green;
      background: $brand_white 0% 0% no-repeat padding-box;
      border: 3px solid $brand_green;
    }
  }

  &.red {
    border: 3px solid $brand_red;
    background: $brand_red 0% 0% no-repeat padding-box;
    color: $brand_white;
    border-radius: 20px;
    margin: 10px;

    &:hover {
      color: $brand_green;
      background: $brand_white 0% 0% no-repeat padding-box;
      border: 3px solid $brand_red;
    }
  }
}

.icon {
  align-self: center;
  margin: 10px;
  padding: 0px;
  background: $brand_white 0% 0% no-repeat padding-box;
  color: $brand_darkblue;
  height: 24px;
  width: 24px;

  .filled {
    background: $brand_white 0% 0% no-repeat padding-box;
    color: $brand_darkblue;
  }
}

.red {
  color: $brand_red;
}

.closeButton {
  &.MuiPaper-root {
    background-color: $brand_white;
    color: $brand_darkblue;
  }
}

.thumbnail {
  height: 200px;
  width: 170px;

  @include media('<=phone') {
    height: 50px;
    width: 42px;
  }

  @include media('>phone', '<=tablet') {
    height: 100px;
    width: 84px;
  }

  @include media('>tablet', '<=desktop') {
    height: 150px;
    width: 126px;
  }

  @include media('>tablet', '<=desktop') {
    height: 200px;
    width: 170px;
  }
}

.viewImageContainer {
  margin: 25px;
  height: 275px;
  width: 250px;
  border: 1px solid $brand_darkergrey;
  align-items: center;
  justify-content: center;
  text-align: center;

  .dragNDrop{
    font: $brand_font12;
  }

  &.MuiGrid-container {
    min-height: 275px;
    height: auto;
    width: 250px;
  }

  &.MuiGrid-root {
    padding: 10px;
    justify-content: flex-start;
  }

  .shadow {
    border: 3px solid $brand_darkergrey;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  }

  .viewImgLabel {
    text-align: center;
    color: $brand_darkblue;
  }

  .stlviewer{
    justify-content: center;
    width:200px;
    height:230px;
  }
  .uploadImg {
    height: 113px;
    width: 104px;
    left: 5px;
    margin: 10px;
  }

  .uploadFile {
    &.MuiSvgIcon-root {
      height: 100px;
      width: 125px;
      fill: $brand_statusnavyblue;
    }
  }

}

.uploadImgTextContainer {
  margin: 25px;
  height: 200px;
  width: 250px;
  border: 1px solid $brand_darkergrey;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: $brand_font12;
}

.uploadImgContainer {
  margin: 25px;
  height: 200px;
  width: 250px;
  border: 1px solid $brand_darkergrey;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: $brand_font12;


  &.MuiGrid-container {
    height: 200px;
    width: 250px;
  }

  .uploadImg {
    height: 113px;
    width: 104px;
    left: 5px;
  }

  .uploadFile {
    &.MuiSvgIcon-root {
      height: 100px;
      width: 125px;
      fill: $brand_statusnavyblue;
    }
  }

}

.shiftleft {
  position: relative;
  right: 20px;
  top: 41px;
  z-index: 20;
  background-color: $brand_white;
  border: 3px solid $brand_icongrey;
  border-radius: 20px
}

.viewImage {
  max-width: 350px;
  max-height: 200px;
}

.cornerBtn {
  position: relative;
  right: 0px;
  top: 0px;
  z-index: 20;
  background-color: $brand_white;
  border: 3px solid $brand_red;
  border-radius: 20px;

  &.MuiSvgIcon-root {
    height: 31px;
    width: 31px;
  }

}

.BtnTopLeftCorner {
  position: relative;
  right: 26px;
  top: 25px;
  z-index: 19;
  background-color: $brand_white;
  border: 3px solid $brand_red;
  border-radius: 20px;

  &.small {
    right: 0px;
    top: 48px;
  }
}

.BtnTopRightCorner {
  position: relative;
  right: -346px;
  top: 25px;
  z-index: 19;
  background-color: $brand_white;
  border: 3px solid $brand_red;
  border-radius: 20px;

  &.small {
    right: -250px;
    top: 48px;
  }
}

.box-calendly {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1100px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 16px;

  @include media('>tablet', '<=desktop') {
    width: 600px;
  }

  @include media('<=desktop') {
    width: 800px;
  }

  @include media('>desktop', '<=1100px') {
    width: 900px;
  }
}

.box-calendlyInModal {
  //position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  width: 1100px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 16px;

  @include media('>tablet', '<=desktop') {
    width: 600px;
  }

  @include media('<=desktop') {
    width: 800px;
  }

  @include media('>desktop', '<=1100px') {
    width: 900px;
  }
}

.tabs {
  .MuiTab-root {
    padding: 0px 10px;
    font-size: 15;
  }
}

.center {
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.crop{
  .cropper-modal{
    background-color: $brand_lightergrey;
  }
}

.statusgreen{
  color: #92D400;
}

.statusorange{
  color: #FFA834;
}

.statusred{
  color: #E61610;
}


.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.Mui-disabled {
  background-color: $brand_bluehover_disable;//rgba($brand_blue, 0.90) !important;
}

img{
  &.uploadImg{
    height: "124px";
    width: "94px";
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}